<template>
  <div
    class="container h-100 section-dynamic align-items-center"
  >
    <div
        class="mh-650 section-content"
        data-aos="fade-up"
        data-aos-easing="ease-in-back"
        data-aos-anchor="#section-p1"
        data-aos-duration="1100"
        data-aos-offset="0"
        data-aos-anchor-placement="top-center"
    >
        <div v-html="config.html_extra"></div>
        <div class="col-md-12">
            <h1 class="v-bios-heading">
                {{config.header}}
            </h1>
            <div v-if="config.html_only == 'basic'"  class="text-block" style="margin-top: 1.5em" v-html="config.html"></div>
            <div v-if="config.html_only == 'basic.html'" v-start-with-html="config.html"></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    test: Boolean,
    config: {
        type: Object,
    },
    id: String,

    dom_id: {
        type: String,
    },
    dom_class: {
        type: String,
    },
    position: {
      type: String,
    },
    header: {
      type: String,
    },
    html_extra: {
      type: String,
    },
    background_image: {
      type: String,
    },
    lang: {
      type: String,
    },
  },
  mounted(){
    //console.log(this)
  },
  directives: {
    startWithHtml: {
      inserted(el, binding) {
        el.insertAdjacentHTML('afterbegin', binding.value);
      }
    }
  }
};
</script>
