<template>
  <div>
    <section
      v-for="config in records"
      :key="config.id"
      :id="config.dom_id"
      :class="config.dom_class"
      v-show="showPage && showPage[config.dom_id]"
      :style="`
          width: 100%;
          height:100%;
          background-color   : #FFF;
          background-color   : radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(218, 236, 255, 1) 100%, rgba(255, 255, 255, 1) 100%);
          background-image   : url(${config.background_image});
          background-position: right 50%;
          background-size    : cover;
          background-repeat  : no-repeat;
          ${ config.position == 'fixed' ? `
            position: fixed;
            top:0;
            left:0;
            right:0;
            bottom:0; 
            z-index: 1040;
          ` : ''}
      `"
    >
      <div
        class="wh-100"
        v-if="config.html_only == 'pure'"
        v-html="config.html"
      ></div>
      <page-section-basic v-else :config="config" />
      <div v-if="config.next_section" class="h-100">
        <div class="page-down">
          <a :href="config.next_section">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M12.51,8.7,10,11,7.49,8.7a.51.51,0,0,0-.72,0,.5.5,0,0,0,0,.71l2.86,2.81a.51.51,0,0,0,.72,0l2.86-2.81a.5.5,0,0,0,0-.71A.51.51,0,0,0,12.51,8.7ZM10,.4A9.6,9.6,0,1,0,19.6,10,9.6,9.6,0,0,0,10,.4Zm0,18A8.35,8.35,0,1,1,18.35,10,8.35,8.35,0,0,1,10,18.35Z" fill="#FFFFFFAA"></path>
            </svg>
          </a>
        </div>
      </div>
    </section>
    <div style="width:100%;height:0; overflow:hidden">
      <img v-for="config in records" :key="'img-'+config.id" :src="config.background_image" />
    </div>

    <modal
      v-if="showModal"
      @close="showModal = false"
      data-aos-duration="1100"
      data-aos-offset="0"
      data-aos-anchor-placement="top-bottom"
    >
    <div
      style="position:relative">
      <!-- <h3 v-if="this.locale == 'zh-CN'" class="v-bios-heading">风险披露 及 免责声明</h3> -->
      <h3 v-if="true || this.locale == 'zh-HK'" class="v-bios-heading">風險披露 及 免責聲明</h3>
      <!-- <h3 v-if="this.locale == 'en'" class="v-bios-heading">Risk Disclosure and Disclaimer</h3> -->
      
      <!-- <div class="position-absolute" style="right: 0;top:0;">
        <a href="javascript: void(0)" style="margin: 0 0.3rem" v-on:click="setLocale('en')">EN</a>
        <a href="javascript: void(0)" style="margin: 0 0.3rem" v-on:click="setLocale('zh-HK')">繁</a>
        <a href="javascript: void(0)" style="margin: 0 0.3rem" v-on:click="setLocale('zh-CN')">簡</a>
      </div> -->

      <div class="row">
        <div v-if="false && this.locale == 'zh-CN'" class="col-md-12" style="padding-top:20px">
          <p>
            投资涉及风险。投资产品的价格波动，有时显著。投资产品的价格可升可跌，并可能变成毫无价值。证券及其他投资产品的交易可以赚取利润亦能招致损失。投资者应基于自己的投资经验，目标和风险承受能力慎重考虑投资产品或本网页提及的服务是否适合他们。投资者在作出任何投资决定前应仔细阅读相关证券/投资服务的条款和细则。
          </p>

          <p>
            本网站内所述的任何资料不应被视为亦不构成对任何人，
            在任何司法管辖区的要约或公开发售、也不构成对任何人发出认购任何基金股份的邀请。请注意本公司之基金未经证监会授权于本港向公众发行。
          </p>

          <p>
            本网页所提供的资料仅供参考，并不拟提供专业建议，投资者不应就此依赖该等资料进行投资。投资者在决定投资前，
            应征询您的投资中介公司的意见并确定相关产品符合自己的 “投资目标”。
          </p>

          <p>
            安立资产管理有限公司(“本公司”)
            已获香港证券及期货事务监察委员会发牌及监管。本网站内所述的任何资料仅供参考。在任何情况下，本公司及/或本公司的任何成员皆无须及不会承担任何直接或间接地因登入或使用本网站或链接到本网站的任何网站而造成的任何损失或责任。 </p>
          <p>本人确认已阅读并了解以上信息。</p>
          <p>&nbsp;</p>
          <button
            type="button"
            class="btn btn-primary btn-lg v-button mr-1"
            style="margin-top: 1rem; padding-left: 2rem; padding-right: 2rem"
            @click="showModal = false"
          >
            同意
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-lg v-button"
            style="margin-top: 1rem; padding-left: 2rem; padding-right: 2rem"
          >
            不同意
          </button>
          <p>&nbsp;</p>
        </div>
        <div v-if="true || this.locale == 'zh-HK'" class="col-md-12" style="padding-top:20px">
          <p>
            投資涉及風險。投資產品的價格波動，有時顯著。投資產品的價格可升可跌，並可能變成毫無價值。證券及其他投資產品的交易可以賺取利潤亦能招致損失。投資者應基於自己的投資經驗，目標和風險承受能力慎重考慮投資產品或本網頁提及的服務是否適合他們。投資者在作出任何投資決定前應仔細閱讀相關證券/投資服務的條款和細則。
          </p>

          <p>
            本網站內所述的任何資料不應被視為亦不構成對任何人，
            在任何司法管轄區的要約或公開發售、也不構成對任何人發出認購任何基金股份的邀請。請注意本公司之基金未經證監會授權於本港向公眾發行。
          </p>

          <p>
            本網頁所提供的資料僅供參考，並不擬提供專業建議，投資者不應就此依賴該等資料進行投資。投資者在決定投資前，
            應徵詢您的投資中介公司的意見並確定相關產品符合自己的 “投資目標”。
          </p>

          <p>
            安立資產管理有限公司(“本公司”)
            已獲香港證券及期貨事務監察委員會發牌及監管。本網站內所述的任何資料僅供參考。在任何情況下，本公司及/或本公司的任何成員皆無須及不會承擔任何直接或間接地因登入或使用本網站或鏈接到本網站的任何網站而造成的任何損失或責任。
          </p>
          <p>本人確認已閱讀並了解以上信息。</p>
          <p>&nbsp;</p>
          <button
            type="button"
            class="btn btn-primary btn-lg v-button mr-1"
            style="margin-top: 1rem; padding-left: 2rem; padding-right: 2rem"
            @click="showModal = false"
          >
            同意
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-lg v-button"
            style="margin-top: 1rem; padding-left: 2rem; padding-right: 2rem"
          >
            不同意
          </button>
          <p>&nbsp;</p>
        </div>
        <div v-if="false && this.locale == 'en'" class="col-md-12" style="padding-top:20px">
          <p>
            投資涉及風險。投資產品的價格波動，有時顯著。投資產品的價格可升可跌，並可能變成毫無價值。證券及其他投資產品的交易可以賺取利潤亦能招致損失。投資者應基於自己的投資經驗，目標和風險承受能力慎重考慮投資產品或本網頁提及的服務是否適合他們。投資者在作出任何投資決定前應仔細閱讀相關證券/投資服務的條款和細則。
          </p>

          <p>
            本網站內所述的任何資料不應被視為亦不構成對任何人，
            在任何司法管轄區的要約或公開發售、也不構成對任何人發出認購任何基金股份的邀請。請注意本公司之基金未經證監會授權於本港向公眾發行。
          </p>

          <p>
            本網頁所提供的資料僅供參考，並不擬提供專業建議，投資者不應就此依賴該等資料進行投資。投資者在決定投資前，
            應徵詢您的投資中介公司的意見並確定相關產品符合自己的 “投資目標”。
          </p>

          <p>
            安立資產管理有限公司(“本公司”)
            已獲香港證券及期貨事務監察委員會發牌及監管。本網站內所述的任何資料僅供參考。在任何情況下，本公司及/或本公司的任何成員皆無須及不會承擔任何直接或間接地因登入或使用本網站或鏈接到本網站的任何網站而造成的任何損失或責任。
          </p>
          <p>本人確認已閱讀並了解以上信息。</p>
          <p>&nbsp;</p>
          <button
            type="button"
            class="btn btn-primary btn-lg v-button mr-1"
            style="margin-top: 1rem; padding-left: 2rem; padding-right: 2rem"
            @click="showModal = false"
          >
            Agree
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-lg v-button"
            style="margin-top: 1rem; padding-left: 2rem; padding-right: 2rem"
          >
            Disagree
          </button>
          <p>&nbsp;</p>
        </div>
      </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { loadMessages } from '~/plugins/i18n'
import PageSectionBasic from "../../components/PageSectionBasic";
import Modal from "../../components/Modal";
import AOS from "aos";
import "aos/dist/aos.css";
import { mapGetters } from 'vuex'

export default {
  components: {
    Modal,
    PageSectionBasic,
  },

  layout: "home_cn",

  data: () => {
    return {
      records: [],
      showPage: {},
      showModal: true,
      title: window.config.appName,
    };
  },

  computed: mapGetters({
    locale: 'lang/locale',
    locales: 'lang/locales'
  }),

  watch: {
    locale: function(){
      this.loadPage()
    },
    showModal: function () {
      console.log("change");
      if (this.showModal) {
        document.getElementById("vendor-app").style.height = "100%";
        document.getElementById("vendor-app").style.overflow = "hidden";
        return;
      }

      AOS.init();
      window.AOS = AOS;
      document.getElementById("vendor-app").style.height = "auto";
      document.getElementById("vendor-app").style.overflow = "auto";
    },
  },
  mounted() {
    //console.log(this.locale)
    this.loadPage()
  },

  methods: {
    loadPage(){
      window.productSelected = (dom_id) => {
        if(!dom_id){
          this.records.forEach((r) => {
            if (r.position == "fixed") {
              this.showPage[r.dom_id] = false;
            }
          });
        this.$forceUpdate()
          return;
        }
        //console.log("ok");
        this.showPage[dom_id] = true;
        this.$forceUpdate()
      };
      window.sendEmail = this.sendEmail 
      
      //console.log("mounted");
      this.getRecords().then((records) => {
        this.records = records.map((r) => {
          if (r.position == "append") {
            this.showPage[r.dom_id] = true;
          }
          return r;
        });
        AOS.init();
        window.AOS = AOS;
        setTimeout(()=>{
          if(window.location.hash){
            window.location.href = window.location.hash
          }
        },500)
      });
    },

    async getRecords() {
      const { data } = await axios.get(
        `https://anllyasset.com/api/page?lang=${this.locale}`
      );
      console.log(data.records);
      return data.records;
    },

    async sendEmail({name, email, subject, content}){
      var reqData = {
        name: document.getElementById(name).value,
        email: document.getElementById(email).value,
        subject: document.getElementById(subject).value,
        content: document.getElementById(content).value,
      }
      console.log(reqData)
      document.getElementById(name).style.border = '1px solid #7393B529'
      document.getElementById(email).style.border = '1px solid #7393B529'
      document.getElementById(subject).style.border = '1px solid #7393B529'
      document.getElementById(content).style.border = '1px solid #7393B529'
      var failed = false
      if(!reqData.name || reqData.name == ''){
        document.getElementById(name).style.border = '1px solid #EE2222'
        failed = true
      }
      if(!reqData.email.match(/[A-Za-z0-9]*@[A-Za-z0-9]*.[A-Za-z]*/g)){
        document.getElementById(email).style.border = '1px solid #EE2222'
        failed = true
      }
      if(!reqData.subject || reqData.subject == ''){
        document.getElementById(subject).style.border = '1px solid #EE2222'
        failed = true
      }
      if(!reqData.content || reqData.content == ''){
        document.getElementById(content).style.border = '1px solid #EE2222'
        failed = true
      }
      if(failed) return

      const { data } = await axios.post(`https://anllyasset.com/api/email`, reqData);
      console.log(data)
      if(data.success){
         document.getElementById(name).value = ""
         document.getElementById(email).value = ""
         document.getElementById(subject).value = ""
         document.getElementById(content).value = ""

        var submitted = "" + document.getElementById("email_submit").value
        if(this.locale == 'zh-CN'){
          document.getElementById("email_submit").innerText  = "已发送" 
        } else if(this.locale == 'zh-HK'){
          document.getElementById("email_submit").innerText  = "已發送" 
        } else {
          document.getElementById("email_submit").innerText  = "Sent" 
        }
        document.getElementById("email_submit").disabled =true

        setTimeout(()=>{
          document.getElementById("email_submit").value = submitted 
          document.getElementById("email_submit").disabled =false
        }, 5000)
      }
    },

    setLocale (locale) {
      if (this.$i18n.locale !== locale) {
        loadMessages(locale)
        this.$store.dispatch('lang/setLocale', { locale })
      }
    }
  },
};
</script>
