<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <slot/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {

  }
};
</script>

<style lang="scss" scoped>
</style>